/** @jsx jsx */
import { jsx, Close } from 'theme-ui'
import { a, useTransition } from 'react-spring'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import '@reach/dialog/styles.css'

export const AnimatedDialogOverlay = a(DialogOverlay)
export const AnimatedDialogContent = a(DialogContent)

export const Panel = ({
  children,
  isOpen,
  color = 'primary',
  onDismiss,
  ...rest
}) => {
  const transition = useTransition(isOpen, {
    from: { opacity: 0, x: `-100%` },
    enter: { opacity: 1, x: `0%` },
    leave: { opacity: 0, x: `-100%` }
  })

  return transition(
    ({ opacity, x }, item) =>
      item && (
        <AnimatedDialogOverlay
          style={{ opacity }}
          sx={{
            background: 'rgba(255,255,255,0.75)',
            overflow: 'hidden',
            zIndex: 950
          }}
          onDismiss={onDismiss}
        >
          <AnimatedDialogContent
            style={{ x }}
            sx={{
              position: 'absolute',
              margin: 0,
              left: 0,
              height: '100%',
              width: ['100%', '80%', '50%'],
              overflow: 'auto'
            }}
            {...rest}
            aria-labelledby="Map"
          >
            {children}
            <Close
              onClick={onDismiss}
              sx={{
                position: 'absolute',
                top: '1em',
                right: '1em',
                width: '4em',
                height: '4em',
                cursor: 'pointer',
                svg: {
                  width: '100%',
                  height: '100%'
                }
              }}
              color="black"
            />
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
  )
}
