/** @jsx jsx */
import { jsx } from 'theme-ui'
import ReactMapboxGl, { Popup, ZoomControl, Marker } from 'react-mapbox-gl'
import { keyframes } from '@emotion/react'
import { useCallback, useRef } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'

import { Panel } from './Panel'
import { useStore } from './store'

const accessToken =
  'pk.eyJ1IjoiaW5nb3ZhbGVudGUiLCJhIjoiY2o0enRidTd5MWVjeTJ3cW5ob251N2tmOSJ9.fbQerF_WycUQA8GVdOy6QA'
const style = 'mapbox://styles/ingovalente/ckkmzo58b591g17lnpcclc6fi'

const Mapbox = ReactMapboxGl({
  accessToken
})

const popUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`

const boundsSelector = state => state.bounds
const boundsCenterSelector = state => state.boundsCenter
const selectedSelector = state => state.selected
const handleSelectSelector = state => state.actions.setSelected

export const Map = ({ highlights, ...rest }) => {
  const selected = useStore(selectedSelector)
  const bounds = useStore(boundsSelector)
  const boundsCenter = useStore(boundsCenterSelector)
  const handleSelect = useStore(handleSelectSelector)
  const onDismiss = useStore(state => state.actions.closeMap)
  const isOpen = useStore(state => state.mapOpen)
  const lastSelected = useRef(() => selected)

  const handleDeselect = useCallback(() => {
    lastSelected.current = selected
    handleSelect(undefined)
  }, [handleSelect, selected])

  return (
    <Panel
      sx={{ bg: 'primary', p: 0 }}
      isOpen={isOpen}
      onDismiss={onDismiss}
      {...rest}
    >
      <Mapbox
        style={style}
        attributionControl={false}
        center={
          selected
            ? [selected.location.lng, selected.location.lat]
            : bounds
            ? boundsCenter
            : lastSelected.current &&
              lastSelected.current.location && [
                lastSelected.current.location.lng,
                lastSelected.current.location.lat
              ]
        }
        fitBounds={selected ? undefined : bounds}
        fitBoundsOptions={{
          padding: 60
        }}
        onClick={handleDeselect}
        containerStyle={{ width: '100%', height: '100%' }}
      >
        {highlights.map(highlight => {
          return (
            <Marker
              key={highlight.id}
              onClick={() => handleSelect(highlight)}
              coordinates={[highlight.location.lng, highlight.location.lat]}
              anchor="bottom"
              style={{
                width: '3.5em',
                height: '3.5em',
                textAlign: 'center',
                display: 'grid',
                alignItems: 'center',
                cursor: 'pointer',
                transition: 'opacity 0.3s ease',
                opacity: selected && selected.id === highlight.id ? 0 : 1
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 276 276"
                sx={{
                  width: '3.5em',
                  height: '3.5em',
                  position: 'absolute',
                  zIndex: -1,
                  transform: `scaleX(${highlight.mapId % 2 === 0 ? -1 : 1})`
                }}
              >
                <path
                  sx={{ fill: 'black' }}
                  d="M94.322 54.102l167.583 112.415c-24.529 44.2-64.485 75.621-119.868 94.262C86.654 279.419 37.874 272.622-4.3 240.386L94.322 54.102z"
                  transform="rotate(-167 128.802 162.563)"
                />
              </svg>
              <div sx={{ fontFamily: 'small', color: 'white' }}>
                {highlight.mapId + 1}
              </div>
            </Marker>
          )
        })}
        {selected && (
          <Popup
            key={selected.id}
            coordinates={[selected.location.lng, selected.location.lat]}
            sx={{
              '.mapboxgl-popup-content': {
                bg: 'white',
                color: 'black',
                fontFamily: 'small',
                fontSize: 2,
                p: 2,
                pb: '10px',
                borderRadius: 0,
                animation: `${popUp} 0.3s ease`
              }
            }}
          >
            {selected.mapId + 1} – {selected.caption}
          </Popup>
        )}
        <ZoomControl position="bottom-right" />
      </Mapbox>
    </Panel>
  )
}
