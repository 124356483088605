/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useCallback, useState, useMemo } from 'react'
import { graphql } from 'gatsby'

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import { PostList } from '../components/PostList'
import { GraphQLErrorList } from '../components/Graphql-error-list'
import { SEO } from '../components/SEO'
import { LayoutContainer } from '../containers/Layout'
import { Index } from '../components/Index'
import { Map } from '../components/Map'
import { useStore } from '../components/store'

export const query = graphql`
  fragment SanityImage on SanityMainImageOrVideo {
    __typename
    ... on SanityMainImage {
      _key
      alt
      asset {
        _id
        metadata {
          dimensions {
            aspectRatio
          }
        }
      }
    }
    ... on SanityVideo {
      _key
      alt
      video {
        asset {
          _id: playbackId
          status
          thumbTime
        }
      }
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    allHighlights: allSanityMapbox {
      edges {
        node {
          id
          caption
          location {
            lat
            lng
            alt
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          title
          publishedAt
          location
          coffee
          location
          images {
            ...SanityImage
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          slug {
            current
          }
          note {
            _key
            title
            images {
              ...SanityImage
            }
            _rawBody
          }
          highlights: map {
            id
            caption
            location {
              lat
              lng
            }
          }
          categories {
            _id
            title
          }
        }
      }
    }
  }
`

const IndexPage = ({ data, errors }) => {
  const openIndex = useStore(state => state.actions.openIndex)
  const openMap = useStore(state => state.actions.openMap)
  const allHighlights = useMemo(() => {
    return data.allHighlights.edges.map(({ node: highlight }, mapId) => {
      return {
        ...highlight,
        mapId
      }
    })
  })

  if (errors) {
    return (
      <LayoutContainer>
        <GraphQLErrorList errors={errors} />
      </LayoutContainer>
    )
  }

  const site = (data || {}).site
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <LayoutContainer>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />

      {postNodes && (
        <PostList nodes={postNodes} allHighlights={allHighlights} />
      )}

      <button
        onClick={openIndex}
        sx={{
          position: 'fixed',
          right: 0,
          top: '50%',
          cursor: 'pointer',
          background: 'none',
          textTransform: 'uppercase',
          fontFamily: 'small',
          color: 'primary',
          border: 'none',
          appearance: 'none',
          fontSize: 1,
          letterSpacing: '0.035em',
          transform: [
            'translate(calc(50% - 1em), -50%) rotate(-90deg)',
            'translate(calc(50% - 1.5em), -50%) rotate(-90deg)'
          ],
          zIndex: 990
        }}
      >
        Week index
      </button>
      <button
        onClick={openMap}
        sx={{
          position: 'fixed',
          left: 0,
          top: '50%',
          cursor: 'pointer',
          background: 'none',
          textTransform: 'uppercase',
          fontFamily: 'small',
          color: 'primary',
          border: 'none',
          appearance: 'none',
          fontSize: 1,
          letterSpacing: '0.035em',
          transform: [
            'translate(calc(-50% + 1em), -50%) rotate(90deg)',
            'translate(calc(-50% + 1.5em), -50%) rotate(90deg)'
          ],
          zIndex: 990
        }}
      >
        Kaart
      </button>
      <Map highlights={allHighlights} />
      <Index posts={postNodes} />
    </LayoutContainer>
  )
}

export default IndexPage
