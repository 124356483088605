/** @jsx jsx */
import groupBy from 'lodash/groupBy'
import getWeek from 'date-fns/getWeek'
import { jsx, Grid, Text, Close } from 'theme-ui'
import { Link } from 'gatsby'
import { useTransition } from 'react-spring'

import { AnimatedDialogOverlay, AnimatedDialogContent } from './Panel'
import { Figure } from './Figure'
import { useStore } from './store'

export const Index = ({ posts }) => {
  const onDismiss = useStore(state => state.actions.closeIndex)
  const isOpen = useStore(state => state.indexOpen)
  const weeks = groupBy(posts, node => {
    return getWeek(new Date(node.publishedAt))
  })

  const transition = useTransition(isOpen, {
    from: { opacity: 0, y: `50%` },
    enter: { opacity: 1, y: `0%` },
    leave: { opacity: 0, y: `50%` }
  })

  return transition(
    ({ opacity, y }, item) =>
      item && (
        <AnimatedDialogOverlay
          style={{ opacity }}
          sx={{
            background: 'white'
          }}
        >
          <AnimatedDialogContent
            style={{ y }}
            sx={{
              display: 'grid',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              margin: 0,
              p: [3, 4],
              justifyItems: 'center',
              gridTemplateColumns: '1fr'
            }}
            aria-labelledby="Week index"
          >
            <Close
              onClick={onDismiss}
              sx={{
                display: 'block',
                cursor: 'pointer',
                mb: 4,
                width: '4em',
                height: '4em',
                outline: 'none',
                color: 'primary',
                '&:focus, &:hover': {
                  color: 'black'
                },
                svg: {
                  width: '100%',
                  height: '100%'
                }
              }}
            />
            <ul sx={{ m: 0, p: 0 }}>
              {Object.keys(weeks)
                .reverse()
                .map(week => {
                  const first = weeks[week][0]
                  const image = first.images.find(
                    img => img.__typename === 'SanityMainImage' && img.asset
                  )
                  return (
                    <Link
                      key={`week-${week}`}
                      sx={{ textDecoration: 'none', color: 'text' }}
                      to={`/#${first.slug.current}`}
                      onClick={() => {
                        onDismiss()
                      }}
                    >
                      <Grid
                        as="li"
                        sx={{
                          alignItems: 'center',
                          columnGap: [3, 4],
                          my: 3,
                          gridTemplateColumns: [
                            '1fr minmax(max-content, 100px) 1fr',
                            '1fr minmax(max-content, 200px) 1fr'
                          ]
                        }}
                      >
                        <Text variant="small" sx={{ textAlign: 'right' }}>
                          Week {week}
                        </Text>
                        <div
                          sx={{ maxWidth: [100, 200], maxHeight: [100, 200] }}
                        >
                          {image && (
                            <Figure
                              maxWidth={200}
                              maxHeight={200}
                              node={image}
                              noCaption
                            />
                          )}
                        </div>
                        <Text>{first.coffee}</Text>
                      </Grid>
                    </Link>
                  )
                })}
            </ul>
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
  )
}
